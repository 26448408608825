<template>
  <div class="term">
    <Header />
    <iframe
      id="iframe_term"
      src="../../static/term/terms.html"
      width="100%"
      height="100%"
      frameborder="0"
      ref="iframeDom"
    ></iframe>
    <Footer />
  </div>
</template>

<script>
export default {
  name: "Redirect",
  beforeCreate() {
    const token = this.$route.query.token;
    const redirect = this.$route.query.redirect;

    if (token && token !== "") {
      this.$storage.set("jwt-token", "Bearer " + token, 3600);
    }

    this.$router.push({
      path: "/" + this.GLOBAL.currentLanguage + redirect,
    });
  },
  mounted() {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
</style>
